export function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export const initials = (email, name) => {
  if (name) {
    const namesArray = name.split(' ');
    let initials;
    if (namesArray.length === 1) {
      initials = `${namesArray[0].charAt(0)}`;
    } else {
      initials = `${namesArray[0].charAt(0)}${namesArray[
        namesArray.length - 1
      ].charAt(0)}`;
    }
    return initials.toUpperCase();
  } else if (email) {
    return email.charAt(0).toUpperCase();
  } else {
    return '?';
  }
};

export const id = () => {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};
