import validation from 'vee-validate/dist/locale/tr';

export default {
  validations: validation.messages,
  languageNames: {
    en: 'English (EN)',
    tr: 'Türkçe (TR)'
  },
  fields: {
    email: 'Email',
    password: 'Parola',
    verification: 'Doğrulama Kodu',
    question: 'Soru',
    kvkk: 'Açık Rıza İzni',
    kvkk2: 'Yurt Dışına Transfer Rıza İzni'
  },
  login: {
    title: 'Giriş Yap',
    login: 'GİRİŞ YAP',
    email_label: 'Email Adresi',
    email_placeholder: 'Kayıt olduğunuz email adresiniz',
    password_label: 'Parola',
    kvkk_doc: 'KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ AYDINLATMA METNİ'
  },
  phoneSupportBox: {
    alternate: 'Yardım almak için {0} nolu telefonu arayabilirsiniz.',
    havingTrouble:
      'Bağlantı veya giriş sorunu yaşıyorsanız {0} nolu telefondan destek alabilirsiniz.'
  },
  verification: {
    title: 'Doğrulama',
    info:
      'Tarafınıza doğrulama kodunu içeren bir mail gönderdik. Lütfen gelen maildeki kodu aşağıdaki alana girerek devam edin.',
    label: 'Doğrulama Kodu',
    verify: 'Doğrula',
    Unauthorized: 'Girdiğiniz doğrulama kodu geçersiz.',
    not_received: 'Doğrulama kodunuz ulaşmadı mı?',
    send_again: 'Tekrar Gönderin'
  },
  tabs: {
    camera: 'Together On-Air',
    questions: 'Soru&Cevap',
    polls: 'Anket'
  },
  questions: {
    questions: 'soru',
    enter_question: 'Sorunuzu buraya yazın',
    send: 'Gönder',
    latest: 'Yeniler',
    popular: 'Popüler',
    no_questions:
      'Hiç soru sorulmamış. İlk soruyu sormak için yukarıdaki alanı kullanabilirsiniz.',
    ask_anonymously: 'Anonim olarak sor?',
    sent_message: 'Sorunuz alınmıştır. Teşekkür ederiz.'
  },
  dialog: {
    start: 'BAŞLA'
  },
  auth: {
    logout: 'Çıkış Yap'
  },
  poll: {
    no_polls: 'Henüz aktif bir anket sorusu bulunmuyor.'
  },
  exhibition: {
    title: 'Sanal Tur'
  }
};
