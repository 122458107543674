import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import settings from '../settings';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: settings.dark,
    themes: {
      [settings.dark ? 'dark' : 'light']: settings.theme
    }
  }
});
