import Vue from 'vue';
import Router from 'vue-router';
import routes from 'vue-auto-routing';
import { createRouterLayout } from 'vue-router-layout';
// context items
import store from './store';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
// import socket from './plugins/socket';
// middlewares
import Pipeline from './middlewares/pipeline';
// import Auth from './middlewares/auth';

import RedirectToHome from '@/components/RedirectToHome';

Vue.use(Router);

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue');
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: [...routes, { path: '*', component: RedirectToHome }]
    }
  ]
});

/**
 * MIDDLEWARES
 */
const globalMiddlewares = [];

router.beforeEach((to, from, next) => {
  const middlewares = [...globalMiddlewares];
  if (to.meta?.middlewares?.length) {
    middlewares.push(...to.meta.middlewares);
  }

  if (!middlewares.length) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    store,
    i18n,
    vuetify,
    router
    // socket
  };

  return middlewares[0]({
    ...context,
    next: Pipeline(context, middlewares, 1)
  });
});

export default router;
