import validation from 'vee-validate/dist/locale/en'

export default {
  validations: validation.messages,
  languageNames: {
    en: 'English (EN)',
    tr: 'Türkçe (TR)',
  },
  fields: {
    email: 'Email',
    password: 'Password',
    verification: 'Verification Code',
    question: 'Question',
    name: 'Name',
    kvkk: 'Privacy Notice',
  },
  login: {
    title: 'Login',
    login: 'Login',
    email_label: 'Email Address',
    email_placeholder: 'Your registered email address',
    password_label: 'Password',
    name_label: 'Name',
    terms_accept: 'I have read and accept {0}.',
    kvkk_doc: 'Privacy Notice',
  },
  phoneSupportBox: {
    havingTrouble: 'If you are having connection or login problems, you can get support from {0}.'
  },
  verification: {
    title: 'Verification',
    info: 'We have sent you an e-mail with the verification code. Please continue by entering the code in the field below.',
    label: 'Verification Code',
    verify: 'Verify',
    Unauthorized: 'The verification code you entered is invalid.',
    not_received: 'Not received your code?',
    send_again: 'Send Again'
  },
  tabs: {
    camera: 'Together On-Air',
    questions: 'Q&A',
    polls: 'Polls',
  },
  questions: {
    questions: 'questions',
    enter_question: 'Write your question here',
    send: 'Send',
    latest: 'Latest',
    popular: 'Popular',
    no_questions:
      'No questions were asked yet. You can use the section above to ask the first question.',
    ask_anonymously: 'Ask anonymously?',
    sent_message: 'Your question has been received.'
  },
  auth: {
    logout: 'Logout'
  },
  poll: {
    voting_closed: 'Voting Closed',
    sent: 'Sent',
    send: 'Send',
    no_polls: 'There are no active polls at the moment.',
    edit_response: 'Edit Response',
  },
  exhibition: {
    title: 'Exhibition',
    foyer: 'Foyer',
  },
  janus: {
    NotAllowedError: 'You are not allowed to access camera and mic.',
    wrong_room: 'No such room could be found or capacity is full.',
    room_destroyed: 'Room destroyed.',
    no_webcam: 'Camera or Mic are not found or not allowed in your device.',
    consent: 'Please give the necessary permission for the camera.'
  },
  webrtc: {
    live: 'LIVE',
    camera: 'Camera',
    mic: 'Microphone',
    retry: 'Try Again',
    show: 'Show My Camera',
    publish_info: 'You are not yet broadcasting. Please use the button below to start broadcasting your camera image to the stage.',
    hide: 'Hide My Camera',
    hide_info: 'Your camera image is currently being broadcast on the stage. Please use the button below to stop broadcasting.',
    err_not_found: 'Camera or Mic are not found or not allowed in your device.',
    err_not_readable: 'Camera or Mic is being used by some other process.',
    err_overconstrained: 'There is no device found that fits your video and audio constraints. You may change video and audio constraints',
    err_not_allowed: 'You are not allowed to access camera and mic.',
    err_type: 'Video/Audio is required.',
    err_screen_share: 'You are not allowed to access screen share.',
    err_websocket: 'WebSocket connection is disconnected.',
    err_connection: 'Disconnected.',
    err_republish: 'Reconnecting...',
    cancel: 'Cancel'
  },
}
