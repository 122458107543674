import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages, { availableLocales } from '../locales';
import settings from '../settings';

Vue.use(VueI18n);

export default new VueI18n({
  locale: settings.defaultLanguage,
  fallbackLocale: settings.defaultLanguage,
  availableLocales: settings.multiLanguage
    ? availableLocales
    : [settings.defaultLanguage],
  messages: settings.multiLanguage
    ? messages
    : { [settings.defaultLanguage]: messages[settings.defaultLanguage] }
});
